import { performLogout } from "./utilityFunctions";
import HomeIcon from "../assets/images/sidebar_icon_home.png";
import InboxIcon from "../assets/images/sidebar_icon_messages.png";
import InboxAsterisk from "../assets/images/sidebar_icon_asterisk.png";
import CalendarIcon from "../assets/images/sidebar_icon_calendar.png";
import ProfileIcon from "../assets/images/sidebar_icon_profile.png";
import SettingsIcon from "../assets/images/sidebar_icon_settings.png";
import ClientsIcon from "../assets/images/sidebar_icon_clients.png";
import IconList from "../assets/images/icon_list.png";
import LogoutIcon from "../assets/images/sidebar_icon_logout.png";

export const SideBarMenuData = {
  admin: {
    links: [
      {
        code: "dashboard",
        name: "Home",
        to: "/admin/dashboard",
        action: null,
        icon: HomeIcon,
      },
      {
        code: "user-approvals",
        name: "Pending Users",
        to: "/admin/approvals",
        action: null,
        icon: SettingsIcon,
        className: "",
      },
      {
        code: "approvals",
        name: "Pending Approvals",
        to: "/admin/document-approvals",
        action: null,
        icon: InboxIcon,
        className: "",
      },
      {
        code: "practitioners",
        name: "List of Practitioners",
        to: "/admin/practitioner/list",
        action: null,
        icon: ProfileIcon,
        className: "",
      },
      {
        code: "users",
        name: "List of Users",
        to: "/admin/user/list",
        action: null,
        icon: ClientsIcon,
        className: "",
      },
      {
        code: "logout",
        name: "Logout",
        to: "",
        action: null,
        icon: LogoutIcon,
        className: "logout",
      },
    ],
  },
  coach: {
    links: [
      {
        code: "dashboard",
        name: "Coach",
        to: "/coach/management",
        action: null,
        icon: SettingsIcon,
      },
      {
        code: "logout",
        name: "Logout",
        to: "",
        action: null,
        icon: LogoutIcon,
        className: "logout",
      },
    ],
  },
  
  client: {
    links: [
      {
        code: "dashboard",
        name: "Home",
        to: "/client/dashboard",
        action: null,
        icon: HomeIcon,
      },
      {
        code: "messages",
        name: "My Messages",
        to: "/client/messages",
        action: null,
        icon: InboxIcon,
        className: "",
      },
      {
        code: "sessions",
        name: "My Sessions",
        to: "/client/sessions",
        action: null,
        icon: CalendarIcon,
        className: "",
      },
      {
        code: "profile",
        name: "My Profile",
        to: "/client/profile",
        action: null,
        icon: ProfileIcon,
        className: "",
      },
      {
        code: "logout",
        name: "Logout",
        to: "",
        action: null,
        icon: LogoutIcon,
        className: "logout",
      },
    ],
  },
  practitioner: {
    links: [
      {
        code: "dashboard",
        name: "Home",
        to: "/practitioner/dashboard",
        action: null,
        icon: HomeIcon,
      },
      // {
      //   code: "calendar",
      //   name: "Calendar",
      //   to: "/practitioner/calendar",
      //   action: null,
      //   icon: CalendarIcon,
      // },
      {
        code: "profile",
        name: "Profile",
        to: "/practitioner/profile",
        action: null,
        icon: ProfileIcon,
      },
      {
        code: "certifications",
        name: "Certifications",
        to: "/practitioner/certs",
        action: null,
        icon: IconList,
      },
      // {
      //   code: "settings",
      //   name: "Settings",
      //   to: "/practitioner/settings",
      //   action: null,
      //   icon: SettingsIcon,
      // },
      // {
      //   code: "clients",
      //   name: "Clients",
      //   to: "/practitioner/clients",
      //   action: null,
      //   icon: ClientsIcon,
      // },
      // {
      //   code: "messages",
      //   name: "Messages",
      //   to: "/practitioner/messages",
      //   action: null,
      //   icon: InboxIcon,
      // },
      // {
      //   code: "quiz",
      //   name: "Quizzes",
      //   to: "/get-matched",
      //   action: null,
      //   icon: InboxAsterisk,
      // },
      {
        code: "logout",
        name: "Logout",
        to: "",
        action: null,
        icon: LogoutIcon,
        className: "logout",
      },
    ],
  },
};

export const sortOptions = [
  { value: 1, label: "Ascending" },
  { value: -1, label: "Descending" },
];

export const specialtyOptions = [
  { value: null, label: "Any" },
  { value: "Anxiety", label: "Anxiety" },
  { value: "Marital", label: "Marital & Premarital" },
  { value: "Relationship Issues", label: "Relationship Issues" },
  { value: "Life Transitions", label: "Life Transitions" },
];

// export const typesOptions = [{ value: "Any", label: "Any" }];
export const genderOptions = [
  { value: null, label: "Any" },
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];

export const meetingOptions = [
  { value: null, label: "Any" },
  { value: "Online", label: "Online" },
  { value: "Appointment", label: "Appointment" },
];

export const costOptions = [
  { value: null, label: "Any" },
  { value: "Asc", label: "Asc" },
  { value: "Desc", label: "Desc" },
];

export const categoryOptions = [
  { value: "Mental Health Therapists", label: "Mental Health Therapists" },
  { value: "Natural Therapists", label: "Natural Therapists" },
  { value: "Parenting & Relationships", label: "Parenting & Relationships" },
  {
    value: "Nutritionists & Dietiticians",
    label: "Nutritionists & Dietiticians",
  },
  { value: "Life & Business Coaches", label: "Life & Business Coaches" },
  { value: "Financial Wellbeing", label: "Financial Wellbeing" },
];

export const gpOptions = [
  { value: "Mental Health Care Plan", label: "Mental Health Care Plan" },
  { value: "Chronic Disease Care Plan", label: "Chronic Disease Care Plan" },
  { value: "Eating Disorder Care Plan", label: "Eating Disorder Care Plan" },
]

export const specialisedCategoryOptions = [
  { value: "Anxiety", label: "Anxiety" },
  { value: "Depression", label: "Depression" },
  { value: "Abuse", label: "Abuse" },
  { value: "Sleep issues", label: "Sleep issues" },
  { value: "Grief and loss", label: "Grief and loss" },
  { value: "Chronic illness and pain", label: "Chronic illness and pain" },
  { value: "Parenting Issues", label: "Parenting Issues" },
  { value: "Phobias", label: "Phobias" },
  { value: "Anger Issues", label: "Anger Issues" },
  { value: "Gender Issues", label: "Gender Issues" },
  { value: "Addiction", label: "Addiction" },
  { value: "Eating Disorder", label: "Eating Disorder" },
  { value: "Financial Stress", label: "Financial Stress" },
  { value: "Money Mindset", label: "Money Mindset" },
  { value: "Spiritual Growth", label: "Spiritual Growth" },
]

export const accuratePersonalityTraitsOptions = [
  { value: "Light hearted", label: "Light hearted" },
  { value: "Compassionate and warm", label: "Compassionate and warm" },
  { value: "Spiritual", label: "Spiritual" },
  { value: "A Parent", label: "A Parent" },
  { value: "Mentor and motivator", label: "Mentor and motivator" },
  { value: "Existential", label: "Existential" },
  { value: "Direct but supportive", label: "Direct but supportive" },
]

/* export const meetingsOptions = [
  { value: "Online", label: "Online" },
  { value: "In-Person", label: "In-Person" },
]; */

export const professionOptions = [
  { value: "Psychologist (PACFA/ACA Accredited)", label: "Psychologist (PACFA/ACA Accredited)" },
  // { value: "Profession 2", label: "Profession 2" },
  // { value: "Profession 3", label: "Profession 3" },
  // { value: "Profession 4", label: "Profession 4" },
  // { value: "Profession 5", label: "Profession 5" },
];

export const stateOptions = [
  { value: "State 1", label: "State 1" },
  { value: "State 2", label: "State 2" },
  { value: "State 3", label: "State 3" },
  { value: "State 4", label: "State 4" },
  { value: "State 5", label: "State 5" },
];

export const monthsOptions = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

export const timezoneOptions = [
  { value: "1", label: "Timezone 1" },
  { value: "2", label: "Timezone 2" },
  { value: "3", label: "Timezone 3" },
  { value: "4", label: "Timezone 4" },
  { value: "5", label: "Timezone 5" },
];

/*export const durationOptions = [
  {
    value: "30",
    label: "30 Minutes",
    data: [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
    ],
  },
  {
    value: "60",
    label: "1 Hour",
    data: [
      "9:00 AM",
      "10:00 AM",
      "11:00 AM",
      "12:00 PM",
      "1:00 PM",
      "2:00 PM",
      "3:00 PM",
      "4:00 PM",
    ],
  },
  {
    value: "90",
    label: "1.5 Hours",
    data: ["9:00 AM", "10:30 AM", "12:00 PM", "1:30 PM", "3:00 PM", "4:30 PM"],
  },
  {
    value: "120",
    label: "2 Hours",
    data: ["9:00 AM", "11:00 AM", "1:00 PM", "3:00 PM"],
  },
];*/

export const durationOptions = [
  {
    value: "30",
    label: "30 Minutes",
    data: [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
    ],
  },
  {
    value: "60",
    label: "1 Hour",
    data: [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
    ],
  },
  {
    value: "90",
    label: "1.5 Hours",
    data: [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
    ],
  },
  {
    value: "120",
    label: "2 Hours",
    data: [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
    ],
  },
];
