import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import QuoteBox from "../../components/QuoteBox";
import Doctor from "../../assets/images/dr_1.jpg";
import Pin from "../../assets/images/pin.png";
import RatingWidget from "../../components/RatingWidget";
import HIF from "../../assets/images/logo_hif.png";
import AU from "../../assets/images/logo_australianunity.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useUserStore } from "../../store/userStore";
import usePractitionerProfile from "../../hooks/usePractitionerProfile";
import useLoader from "../../hooks/useLoader";
import usePractitioner from "../../hooks/usePractitioner";
import useAdminPendingApproval from "../../hooks/useAdminPendingApproval";
import Select from "react-select";
import { categoryOptions, professionOptions, gpOptions, specialisedCategoryOptions, accuratePersonalityTraitsOptions } from "../../utils/globals";
import { Profession, Actions, Update } from "./form";

function AdminDashboardPractitionerProfile() {
  const maxrating = 5;
  const reviewscount = 24;
  const { id } = useParams();
  const { profile, errorMessage } = usePractitionerProfile(id);
  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const navigate = useNavigate();
  const { acceptHandler, approve } = useAdminPendingApproval();
  const { professions } = usePractitioner();
  const [profession, setProfession] = useState('Psychologist');
  const [professionText, setProfessionText] = useState('Psychologist');  
  const [showUpdateRole, setUpdateRole] = useState(false);
  const doUserUpdate = useUserStore((state) => state.doUserUpdate);


  if (errorMessage) {
    closeMessages();
    showMessage(errorMessage);
  }

  return (
    <div className="admin-practitioner-profile-page">
      <Header></Header>

      {profile && (
        <div className="profile-page">
          <div className="profile-header">
            <img className="photo" src={Doctor}></img>
            <div className="details">
              <h1>{`${profile.firstname} ${profile.lastname}`}</h1>
              <h2>{profile.position} </h2>
              <div className="location">
                <img src={Pin}></img>
                {profile.location}
              </div>
            </div>
          </div>
          <div className="profile-body">
            <div className="left">
              <div className="more-info">
                <RatingWidget
                  rating={profile.rating}
                  maxrating={maxrating}
                ></RatingWidget>
                <Link className="reviews-link">
                  {profile.reviewscount} Verified Reviews
                </Link>

                {profile.status == "pending" && approve != "active" && (
                  <button
                    className="lumi-btn book-now"
                    onClick={async () => acceptHandler(profile._id)}
                  >
                    Approve
                  </button>
                )}

                <span className="note">
                  {approve == "active" ? (
                    <div>
                      You have approved <b>{profile.firstname}</b>!
                    </div>
                  ) : (
                    <div>
                      {profile.firstname} status is currently set to{" "}
                      <b>{profile.status}</b>
                    </div>
                  )}
                </span>

                <button
                  className="lumi-btn send-message"
                  onClick={() => navigate(`/admin/approvals`)}
                >
                  Back to Admin Page
                </button>

                <span className="note">
                  <b>Accept The Following Care Plans:</b>

                  {profile
                    ? profile.gpcategories.map((item) => {
                        return <div>{item}</div>;
                      })
                    : ""}
                </span>
              </div>
            </div>
            <div className="right">
              <h3 className="about-me">About Me</h3>
              <p>{profile.message}</p>
              <h3 className="specialties">Specialties</h3>
              <p>Parenting & Relationships</p>
              <h3 className="role">Profession</h3>
              <p>
                {showUpdateRole === false && (
                  <Update
                  showUpdateRole={showUpdateRole}
                  setUpdateRole={setUpdateRole}
                  profile={profile}
                  onAdd={async () => {
                    setUpdateRole((prevCheck) => !prevCheck);
                  }}
              />
              )}
                {showUpdateRole === true && (
                  <Profession
                    value={profession}
                    setValue={setProfession}
                    professions={professions}
                    setValueText={setProfessionText}
                  />
                )}
              </p>
              <h3 className="right-one">Am I the right one for you?</h3>
              <p>{profile.whyme}</p>

              <Actions
                onAdd={async () => {
                  showLoader("Saving booking..");
                  const response = await doUserUpdate({
                    position: professionText,
                    _id: profile._id
                  }); 
                  closeMessages();
                  if (response.error) {
                    showError(response.message);
                    return;
                  }
                  showMessage("Profile successfully updated");
                }}
              />

              <div className="membership-medicare">
                <div className="memberships-left">
                  <h5>Memberships</h5>
                  <div className="icons">
                    <img src={HIF}></img>
                  </div>
                </div>
                <div className="memberships-right">
                  <h5>Medicare</h5>
                  <div className="icons">
                    <img src={HIF}></img>
                    <img src={AU}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <QuoteBox></QuoteBox>
      <Footer></Footer>
    </div>
  );
}

export default AdminDashboardPractitionerProfile;
