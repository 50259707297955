function CertificationCard({ cert }) {
  return (
    <>
        <li>
          <a download href={cert}>
            <img src={cert} width="150" height="150" />
          </a>
        </li>
    </>
  );
}

export default CertificationCard;
