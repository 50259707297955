import { useState } from "react";
import { Link } from "react-router-dom";

import ClientPhoto from "../../assets/images/dr_1.jpg";
import ConfirmModal from "../ConfirmModal";
import { performLogout } from "../../utils/utilityFunctions";
import SideBarMenuItem from "./components/SideBarMenuItem";
import useDashboardSidebar from "./hooks/useDashboardSideBar";
import useProfile from "../../hooks/useProfile";
import DefaultAvatar from "../../assets/images/icon_default_avatar.png";
import useUserProfile from "../../hooks/useUserProfile";
import useIsMobile from "../../hooks/useIsMobile";
import LumiLogo from "../../assets/images/logo.png";
import DateTimePill from "../../components/DateTimePill";
import { capitalize } from "../../helpers/practitionerHelper";

function DashboarSidebar({ role }) {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const { menuItems } = useDashboardSidebar(role);
  const { firstName, lastName, photo, _id , name, avatar} = useProfile();
  const isMobile = useIsMobile();
  //const { profile } = useUserProfile(_id, role);

  const [hamburgerStatus, setHamburgerStatus] = useState(false);
  const hamburgerClickHandler = () => {
    setHamburgerStatus(!hamburgerStatus);
  };

  const userName = role == 'admin' ? capitalize(name) : `${firstName} ${lastName}`

  return (
    <div className="dashboard-sidebar">
      {isMobile ? (
        <>
          <ul
            className={
              hamburgerStatus
                ? "sidebar-navigation sn-open"
                : "sidebar-navigation"
            }
          >
            {menuItems.map((item) => {
              if (item.className == "logout") {
                item.action = () => setShowLogoutConfirm(true);
              }
              return (
                <SideBarMenuItem key={item.code} {...item}></SideBarMenuItem>
              );
            })}
          </ul>
          <div className="mobile-header">
            <img className="lumi-logo" src={LumiLogo}></img>
            <div id="hamburger" onClick={hamburgerClickHandler}>
              <div id="menuToggle">
                <input type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                <div></div>
              </div>
            </div>
          </div>
          <div className="mobile-sub-header">
            <img
              className="profile-photo"
              src={avatar ? avatar : DefaultAvatar}
            ></img>
            <div className="pill-container">
              <DateTimePill></DateTimePill>
            </div>
          </div>
        </>
      ) : (
        <>
          <img
            className="profile-photo"
            src={avatar ? avatar : DefaultAvatar}
          ></img>
          <span className="profile-name">{userName}</span>
          <ul className="sidebar-navigation">
            {menuItems.map((item) => {
              if (item.className == "logout") {
                item.action = () => setShowLogoutConfirm(true);
              }
              return (
                <SideBarMenuItem key={item.code} {...item}></SideBarMenuItem>
              );
            })}
          </ul>
        </>
      )}

      <ConfirmModal
        show={showLogoutConfirm}
        setShow={setShowLogoutConfirm}
        title="Logout"
        message="Are you sure you want to logout?"
        onConfirm={() => {
          performLogout();
        }}
        onCancel={() => {}}
      ></ConfirmModal>
    </div>
  );
}
export default DashboarSidebar;
