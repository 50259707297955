import DashboardLayout from "../../layouts/DashboardLayout";
import DateTimePill from "../../components/DateTimePill";
import Select from "react-select";
import { useEffect, useRef, useState } from "react";
import { usePractitionerStore } from "../../store/practitionerStore";
import { useUserStore } from "../../store/userStore";

import PlusIcon from "../../assets/images/icon_plus_round.png";
import ClientPhoto from "../../assets/images/dr_1.jpg";
import uploadSupportingDocs from "../../assets/images/upload-supporting-docs.png";

import Editor from "../../components/QuillEditor";
import useProfile from "../../hooks/useProfile";
import usePractitionerProfile from "../../hooks/usePractitionerProfile";
import useLoader from "../../hooks/useLoader";
import {
  flattenValuesFromSelect,
  inflateValuesFromSelect,
} from "../../utils/utilityFunctions";
import {
  categoryOptions,
  professionOptions,
  gpOptions,
  specialisedCategoryOptions,
  accuratePersonalityTraitsOptions,
} from "../../utils/globals";
import UploadIcon from "../../assets/images/icon_upload.png";
import MedCert from "../../assets/images/med-cert.png";
import CropperModal from "../../components/CropperModal";
import { updateProfilePhoto } from "../../api";
import Certifications from "./components/CertificationCard";
import { useNavigate } from "react-router-dom";

function auto_grow() {
  const textareas = document.getElementsByTagName("textarea");

  for (let t of textareas) {
    t.style.height = "5px";
    t.style.height = t.scrollHeight + "px";
  }
}

function PractitionerCerts() {
  const navigate = useNavigate();
  const [professionVal, setProfessionVal] = useState("");
  const [serviceVal, setServiceVal] = useState("");
  const [backgroundVal, setBackgroundVal] = useState("");
  const [certificationsVal, setCertificationsVal] = useState("");

  const { _id } = useProfile();
  const doUpdatePractitioner = usePractitionerStore(
    (state) => state.doUpdatePractitioner
  );

  const setProfile = useUserStore((state) => state.setProfile);

  const { profile: practitionerProfile, errorMessage: fetchMessage } =
    usePractitionerProfile(_id);

  useEffect(() => {
    if (practitionerProfile) {
      setProfessionVal(practitionerProfile.position);
      setServiceVal(practitionerProfile.message);
      setBackgroundVal(practitionerProfile.background);
      setCertificationsVal(practitionerProfile.certifications);
      setSelectedCategories(
        inflateValuesFromSelect(practitionerProfile.categories)
      );
      setSelectedGpCategories(
        inflateValuesFromSelect(practitionerProfile.gpcategories)
      );
      setSelectedSpecialisedCategories(
        inflateValuesFromSelect(practitionerProfile.specialisedcategories)
      );
      setSelectedAccuratePersonalityTraitsCategories(
        inflateValuesFromSelect(
          practitionerProfile.accuratepersonalitytraitscategories
        )
      );
      setSelectedProfession(
        inflateValuesFromSelect([practitionerProfile.position])
      );
      setSelectedWorktype(practitionerProfile.worktype);
      setSelectedAgeScopetype(practitionerProfile.agescopetype);
      setSelectedClienttype(practitionerProfile.clienttype);
      setSelectedQualifiedMentalProftype(
        practitionerProfile.qualifiedmentalproftype
      );
      setSelectedSupportType(practitionerProfile.supporttype);
    }
  }, [practitionerProfile]);

  const professionRef = useRef();
  const [selectCategories, setSelectedCategories] = useState();
  const [selectGpCategories, setSelectedGpCategories] = useState();
  const [
    selectAccuratePersonalityTraitsCategories,
    setSelectedAccuratePersonalityTraitsCategories,
  ] = useState();
  const [selectSpecialisedCategories, setSelectedSpecialisedCategories] =
    useState();
  const [selectedProfession, setSelectedProfession] = useState();
  const [selectWorkType, setSelectedWorktype] = useState(0);
  const [selectAgeScopeType, setSelectedAgeScopetype] = useState(0);
  const [selectClientType, setSelectedClienttype] = useState(0);
  const [selectQualifiedMentalProftype, setSelectedQualifiedMentalProftype] =
    useState(0);
  const [selectSupportType, setSelectedSupportType] = useState(0);

  const handleCategorySelect = (data) => {
    setSelectedCategories(data);
  };

  const handleGpSelect = (data) => {
    setSelectedGpCategories(data);
  };

  const handleaccuratePersonalityTraitsSelect = (data) => {
    setSelectedAccuratePersonalityTraitsCategories(data);
  };

  const handleSpecialisedCategoriesSelect = (data) => {
    setSelectedSpecialisedCategories(data);
  };

  const handleWorkType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedWorktype(value);
    }
  };

  const handleAgeScopeType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedAgeScopetype(value);
    }
  };

  const handleClientType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedClienttype(value);
    }
  };

  const handleQualifiedMentalProfType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedQualifiedMentalProftype(value);
    }
  };

  const handleSupportType = (e) => {
    const { nodeName, value } = e.target;
    if (nodeName === "INPUT") {
      setSelectedSupportType(value);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      <div className="lumi-drop-indicator">
        <img src={PlusIcon}></img>
      </div>
    );
  };

  useEffect(() => {
    auto_grow();
  }, []);

  useEffect(() => {
    if (fetchMessage) showError(fetchMessage);
  }, [fetchMessage]);

  const { showLoader, showMessage, closeMessages, showError } = useLoader();
  const uploadInput = useRef();

  const [showCropper, setShowCropper] = useState(false);
  const [ageUpload, setAgeUpload] = useState(true);
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [cropperFile, setCropperFile] = useState(null);

  const handleCropperConfirm = async (file) => {
    setShowCropper(false);
    setAvatarUrl(null);
    setCropperFile(file);

    showLoader("Uploading photo. Please wait...");
    const result = await updateProfilePhoto({
      certificationuploads: file.url,
      _id: _id,
    });
    if (result.error) {
      showCropper(result.message);
    } else {
      setProfile(result.result);
      showMessage("Profile photo has been updated successfully.");
      navigate(0);
    }
    closeMessages();
  };

  const handleCropperCancel = () => {
    setShowCropper(false);
    setCropperFile(null);
    setAvatarUrl(null);
  };

  const getUploadCertificationSource = () => {
    return uploadSupportingDocs;
  };

  const getUploadScopingCertificationSource = () => {
    return uploadSupportingDocs;
  };

  return (
    <DashboardLayout>
      <div className="practitioner-cert-profile">
        <div className="title-bar">
          <h1>Certifications</h1>
          <div>
            <DateTimePill></DateTimePill>
          </div>
        </div>
        <div className="subhead">Add your relevant certifications below. These will be reviewed along with your profile for approval.</div>
        {practitionerProfile ? (
          <div className="dash-prac-profile-container">
            <div className="dash-prac-profile-left">
              <div className="single-select-box">
                <h2>Professional Indemnity Certificate of Currency (required)</h2>
                <div>
                  <div
                    className="age-scope"

                  >
                    {/* <div>Upload supporting documents:</div> */}
                    <img
                      className="profile-photo"
                      src={getUploadScopingCertificationSource()}
                      onClick={() => {
                        uploadInput.current.click();
                      }}
                    ></img>

                    <input
                      className="image-file-input"
                      type="file"
                      ref={uploadInput}
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={(e) => {
                        if (e.target.files) {
                          setAvatarUrl(URL.createObjectURL(e.target.files[0]));
                          setShowCropper(true);
                          setAgeUpload(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="single-select-box">
                <h2>
                  Working with children
                  <span>Do you work with youth under age of 16. If so, please upload your working with children or vulnerable people check.</span>
                </h2>
                <div className="radio-buttons" onChange={handleAgeScopeType}>
                  <label className="radio-button">
                    <input
                      type="radio"
                      name="ageScope"
                      value="no"
                      checked={selectAgeScopeType == "no" ? true : false}
                    />
                    <span className="radio-tick"></span>
                    No
                  </label>
                  <label className="radio-button">
                    <input
                      type="radio"
                      name="ageScope"
                      value="yes"
                      checked={selectAgeScopeType == "yes" ? true : false}
                    />
                    <span className="radio-tick"></span>
                    Yes
                  </label>
                </div>
                <div>
                  <div
                    className="age-scope"
                    style={{
                      display: selectAgeScopeType == "yes" ? "block" : "none",
                    }}
                  >
                    <div>Upload supporting documents:</div>
                    <img
                      className="profile-photo"
                      src={getUploadScopingCertificationSource()}
                      onClick={() => {
                        uploadInput.current.click();
                      }}
                    ></img>

                    <input
                      className="image-file-input"
                      type="file"
                      ref={uploadInput}
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={(e) => {
                        if (e.target.files) {
                          setAvatarUrl(URL.createObjectURL(e.target.files[0]));
                          setShowCropper(true);
                          setAgeUpload(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="single-select-box">
                <h2>Certification Upload</h2>
                <div>
                  <div
                    className="age-scope"
                  >
                    {/* <div>Upload supporting documents:</div> */}
                    <img
                      className="profile-photo"
                      src={getUploadScopingCertificationSource()}
                      onClick={() => {
                        uploadInput.current.click();
                      }}
                    ></img>

                    <input
                      className="image-file-input"
                      type="file"
                      ref={uploadInput}
                      accept="image/png, image/jpeg, image/jpg"
                      onChange={(e) => {
                        if (e.target.files) {
                          setAvatarUrl(URL.createObjectURL(e.target.files[0]));
                          setShowCropper(true);
                          setAgeUpload(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="single-select-box">
                <div>Uploaded Certifications:</div>
                <ul>
                  {practitionerProfile.certificationuploads &&
                  practitionerProfile.certificationuploads.length > 0 ? (
                    practitionerProfile.certificationuploads.map((item) => {
                      return <Certifications cert={item.cert} />;
                    })
                  ) : (
                    <div align="center"> No certifications uploaded. </div>
                  )}
                </ul>
              </div>
            </div>

            <div className="clearboth"></div>
            <button
              className="save-button lumi-btn"
              onClick={async () => {
                showLoader("Saving changes..");
                let data = {};
                data.position = selectedProfession.value;
                data.message = serviceVal;
                data.background = backgroundVal;
                data.certifications = certificationsVal;
                data.categories = flattenValuesFromSelect(selectCategories);
                data.gpcategories = flattenValuesFromSelect(selectGpCategories);
                data.accuratepersonalitytraitscategories =
                  flattenValuesFromSelect(
                    selectAccuratePersonalityTraitsCategories
                  );
                data.specialisedcategories = flattenValuesFromSelect(
                  selectSpecialisedCategories
                );
                data.worktype = selectWorkType;
                data.supporttype = selectSupportType;
                data.agescopetype = selectAgeScopeType;
                data.clienttype = selectClientType;
                data.qualifiedmentalproftype = selectQualifiedMentalProftype;
                data._id = _id;
                const response = await doUpdatePractitioner(data);

                closeMessages();

                if (response.error) {
                  showError(response.message);
                  return;
                }

                showMessage("Profile updated successfully!");


              }}
            >
              Save Changes
            </button>
          </div>
        ) : (
          <div>Loading profile</div>
        )}
      </div>

      <CropperModal
        show={showCropper}
        avatarUrl={avatarUrl}
        handleCancel={handleCropperCancel}
        handleConfirm={handleCropperConfirm}
        aspectRatio={1 / 1}
      ></CropperModal>
    </DashboardLayout>
  );
}

export default PractitionerCerts;
